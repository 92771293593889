import React, { Fragment, Component } from 'react';
import classNames from 'classnames';

class CalculatorTabs extends Component {
  state = {
    activeTab: 1,
  };

  render() {
    const { activeTab } = this.state;

    return (
      <Fragment>
        <ul className="Tabs__nav list--clean" role="tablist">
          <li
            className={classNames('Tabs__nav__item', activeTab === 1 && 'is-active')}
            onClick={() => this.setState({ activeTab: 1 })}
            onKeyDown={() => this.setState({ activeTab: 1 })}
            role="tab"
          >
            <span>Freebies</span>
          </li>
          <li
            className={classNames('Tabs__nav__item', activeTab === 2 && 'is-active')}
            onClick={() => this.setState({ activeTab: 2 })}
            onKeyDown={() => this.setState({ activeTab: 2 })}
            role="tab"
          >
            <span>Format</span>
          </li>
        </ul>
        <div className="Tabs__content">
          <div
            className={classNames('Tabs__content__item', activeTab === 1 && 'is-active')}
            role="tabpanel"
            tabIndex={0}
          >
            <ul className="list--clean list--check-green">
              <li>Formatting (MLA, APA, Chicago, custom, etc.)</li>
              <li>Title page & bibliography</li>
              <li>24/7 customer support</li>
              <li>Plagiarism report upon request</li>
              <li>Chat with your writer</li>
            </ul>
          </div>
          <div
            className={classNames('Tabs__content__item', activeTab === 2 && 'is-active')}
            role="tabpanel"
            tabIndex={0}
          >
            <ul className="list--clean list--check-green">
              <li>275 word/double-spaced page</li>
              <li>12 point Arial/Times New Roman</li>
              <li>Double, single, and custom spacing</li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CalculatorTabs;
